.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.5s, color 0.5s;
}

.expanded-component {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #333;
  color: #fff;
  font-size: 36px;
  z-index: 2;
}

.landing-page {
  height: 100vh;
}

.navbar {
  background-image: linear-gradient(
    to bottom,
    rgb(241, 241, 241) 0,
    #ffffff 100%
  ) !important; /* override background image gradient w/ flat color */
}

.project-container {
  height: 100%;
}

.project {
  margin-bottom: 20px;
}

.media-container {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

h2 {
  margin-bottom: 10px;
}

p {
  margin-bottom: 0;
}

body {
  font-family: 'Lato', sans-serif;
}
